@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/* ========================= */
#primary-nav .level-1 {
  width: 33%; }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

.brand__lockup img {
  height: 84px; }

/* =========================
   ~ Mixins
   ====================== */
/* =============================================================================
   ~Components
   ========================================================================== */
/*.hero-image {
//        margin: 0 -5px;
        @include breakpoint($sm) {
            margin: 0 auto 1em;
        }
    }*/
.title-bar {
  font-family: "1898Sans-Regular", sans-serif;
  padding-top: 5px; }
  @media screen and (max-width: 767px) {
    .title-bar {
      font-size: 20px; } }

.contact-info {
  padding-top: 10px; }

.node-type-news-post .post-date {
  color: #666666;
  font-weight: 600;
  text-transform: uppercase; }

.node-type-news-post .pane-node-field-news-category {
  clear: both; }

.node-type-news-post .hero-image {
  margin-bottom: 20px; }

.node-type-news-post .field-name-field-news-category h3 {
  color: #000;
  margin-top: 0;
  font-size: inherit;
  line-height: inherit; }

.addthis_toolbox {
  margin-bottom: 10px; }
  .addthis_toolbox .at-icon-wrapper {
    display: none; }
  .addthis_toolbox .btn-share {
    border-radius: 50%;
    margin-right: 10px;
    width: 22px !important;
    height: 22px; }
  .addthis_toolbox .icon-facebook:after {
    color: #e7751d;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: .2em;
    padding-left: 3px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .addthis_toolbox .icon-facebook:hover:after, .addthis_toolbox .icon-facebook:active:after {
    text-decoration: none; }
  .addthis_toolbox .icon-facebook:after {
    font-size: 16px;
    line-height: 1.8em; }
  .addthis_toolbox .icon-twitter:after {
    color: #e7751d;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: .2em;
    padding-left: 3px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .addthis_toolbox .icon-twitter:hover:after, .addthis_toolbox .icon-twitter:active:after {
    text-decoration: none; }
  .addthis_toolbox .icon-twitter:after {
    padding-left: 0px;
    font-size: 16px;
    line-height: 1.8em; }
  .addthis_toolbox .icon-linkedin:after {
    color: #e7751d;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: .2em;
    padding-left: 3px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .addthis_toolbox .icon-linkedin:hover:after, .addthis_toolbox .icon-linkedin:active:after {
    text-decoration: none; }
  .addthis_toolbox .icon-linkedin:after {
    font-size: 16px;
    line-height: 1.8em; }
  .addthis_toolbox .icon-email:after {
    color: #e7751d;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: .2em;
    padding-left: 3px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .addthis_toolbox .icon-email:hover:after, .addthis_toolbox .icon-email:active:after {
    text-decoration: none; }
  .addthis_toolbox .icon-email:after {
    font-size: 16px;
    line-height: 1.8em; }

.select2-container {
  width: 280px !important; }

.pager--infinite-scroll li a {
  border-radius: 20px;
  border: 4px solid #e7751d;
  padding: 6px 20px;
  font-size: 13px;
  width: 220px; }
  .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
    border-color: #cf4520;
    background-color: #e7751d;
    color: #fff; }

.view-news .view-teaser {
  border-bottom: 1px solid #dddddd;
  padding-top: 20px; }

.view-teaser .teaser-title a {
  color: #555555; }

.view-teaser .post-date {
  color: #666666;
  font-weight: 600;
  text-transform: uppercase; }

.view-teaser img.panopoly-image-quarter {
  float: none;
  margin: 0 0 0.25em 0;
  width: auto;
  max-width: none; }
  @media screen and (min-width: 768px) {
    .view-teaser img.panopoly-image-quarter {
      float: left;
      margin: 0.25em 1.5em 1.3em 0;
      width: 35%;
      max-width: 35%; } }

.view-profiles:before, .view-profiles:after {
  content: " ";
  display: table; }

.view-profiles:after {
  clear: both; }

.view-profiles .views-view-grid {
  margin: 0; }
  @media screen and (min-width: 768px) {
    .view-profiles .views-view-grid .visible-sm {
      display: none !important; } }

.view-profiles .grid-title {
  display: none; }

.view-profiles .gridcol {
  position: relative;
  background: #efefef;
  text-align: center;
  float: left;
  padding: 12px;
  margin-bottom: 2em; }
  .view-profiles .gridcol:after {
    content: '';
    position: absolute;
    height: 100%;
    background: #efefef; }
  @media screen and (min-width: 768px) {
    .view-profiles .gridcol {
      width: 28%;
      margin-right: 8%;
      text-align: left; } }
  .view-profiles .gridcol:nth-child(3n) {
    margin-right: 0; }
  .view-profiles .gridcol .profile-list-image {
    text-align: center; }
    .view-profiles .gridcol .profile-list-image img {
      width: 144px;
      /*@include breakpoint($sm) {
                        width: 100%;
                    }*/ }
  .view-profiles .gridcol h2 {
    color: #000;
    font-size: 1.5rem; }
  .view-profiles .gridcol .professional-title {
    font-weight: 400;
    color: #000;
    line-height: 1.4;
    font-size: 1.4rem;
    text-transform: none;
    margin-bottom: 1em; }
  @media screen and (min-width: 768px) {
    .view-profiles .gridcol .profile_link {
      display: none;
      padding: 0;
      /*position: absolute;
                    bottom: 5px;*/ } }
  .view-profiles .gridcol .profile_link p {
    margin: 0; }
  .view-profiles .gridcol .profile_link a {
    display: block;
    color: #000;
    font-size: 1.4rem; }
    @media screen and (min-width: 768px) {
      .view-profiles .gridcol .profile_link a {
        color: #ffc72c; } }
  @media screen and (min-width: 768px) {
    .view-profiles .gridcol:not(.no-hover):hover {
      background: #b61911;
      color: #fff; }
      .view-profiles .gridcol:not(.no-hover):hover .profile_link {
        display: block; }
      .view-profiles .gridcol:not(.no-hover):hover h2 {
        color: #fff; }
      .view-profiles .gridcol:not(.no-hover):hover .professional-title {
        display: none;
        opacity: 0; } }

.view-services-programs {
  clear: both; }
  .view-services-programs .views-row {
    border-bottom: 1px solid #efefef;
    padding-bottom: 2em;
    margin-bottom: 2em; }
    .view-services-programs .views-row:before, .view-services-programs .views-row:after {
      content: " ";
      display: table; }
    .view-services-programs .views-row:after {
      clear: both; }
    .view-services-programs .views-row .views-field-title h3 {
      margin-top: 0; }
    .view-services-programs .views-row img {
      border-radius: 0;
      margin-bottom: 1em; }
      @media screen and (min-width: 768px) {
        .view-services-programs .views-row img {
          float: left;
          margin-right: 25px;
          margin-bottom: 0em; } }

.field-collection-container {
  border-bottom: none; }

.services-accordion {
  position: relative; }
  .services-accordion h3 {
    border-top: 1px solid #dddddd;
    background: none;
    padding-left: 0;
    padding-top: 15px;
    margin-top: 15px;
    color: #000;
    cursor: pointer;
    /*&:after {
                content: "+";
            }*/ }
    .services-accordion h3 .ui-icon {
      display: none; }
    .services-accordion h3:first-child {
      border-top: none; }
    .services-accordion h3.state-active {
      /*.arrow-right {
                    -webkit-transform: rotate(90deg);
                    -moz-transform:    rotate(90deg);
                    -ms-transform:     rotate(90deg);
                    -o-transform:      rotate(90deg);
                    transform:         rotate(90deg);
                }*/ }
      .services-accordion h3.state-active .arrow-right {
        position: relative;
        width: 16px;
        height: auto;
        background-color: #000;
        display: inline-block;
        zoom: 1;
        margin-left: 15px;
        margin-bottom: 8px;
        padding: 5px 10px 0px 0px;
        color: white; }
        .services-accordion h3.state-active .arrow-right:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          left: 0;
          border-top: 5px solid #000;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: none;
          margin: 0px 0px 0 0px; }
        .services-accordion h3.state-active .arrow-right:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          left: 0;
          border-top: 5px solid white;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          margin: -5px 0px 0 0px; }
    .services-accordion h3 .arrow-right {
      position: relative;
      height: 16px;
      background-color: #000;
      display: inline-block;
      zoom: 1;
      margin-left: 15px;
      padding: 10px 5px 0px 0px;
      color: white; }
      .services-accordion h3 .arrow-right:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 5px solid #000;
        border-bottom: 8px solid transparent;
        margin: -10px 0px 0 5px; }
      .services-accordion h3 .arrow-right:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        border-top: 8px solid transparent;
        border-left: 5px solid white;
        border-bottom: 8px solid transparent;
        margin: -10px 0px 0 0px; }
  .services-accordion .accordion-panel {
    display: none;
    background: #f7f7f7;
    padding: 20px;
    margin-bottom: 2em; }

.pane-events-calendar-panel-pane-3 {
  margin-bottom: 3em; }
  .pane-events-calendar-panel-pane-3:before, .pane-events-calendar-panel-pane-3:after {
    content: " ";
    display: table; }
  .pane-events-calendar-panel-pane-3:after {
    clear: both; }
  .pane-events-calendar-panel-pane-3 h3 {
    margin-bottom: 1em; }
  .pane-events-calendar-panel-pane-3 .views-row {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 30%;
    float: left;
    margin-right: 5%;
    border-right: 1px solid #dddddd; }
    .pane-events-calendar-panel-pane-3 .views-row:last-child {
      margin-right: 0;
      border-right: none; }
    .pane-events-calendar-panel-pane-3 .views-row .views-field-title {
      float: left;
      width: 68%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 1.6rem; }

.pane-events-calendar-panel-pane-1 {
  margin-top: 30px; }
  .pane-events-calendar-panel-pane-1 .fc-header-title h2 {
    font-size: 20px; }
  .pane-events-calendar-panel-pane-1 .fc-button.ui-state-hover {
    border: none;
    background: #cccccc; }
  .pane-events-calendar-panel-pane-1 .fc-button .fc-button-content {
    line-height: 1; }

.pane-events-calendar-panel-pane-3 .event-date {
  float: left;
  line-height: .9;
  padding: 3px 8px 0 0;
  width: 32%;
  font-weight: bold;
  color: #2f3237;
  min-width: 55px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.7rem; }
  .pane-events-calendar-panel-pane-3 .event-date p.days {
    margin: 0;
    margin-top: 5px;
    font-size: 2.8rem; }

.fc-event-rsvp-link,
.fc-event-location,
.fc-event-info,
.fc-event-date {
  display: none; }

.ui-state-highlight {
  background: #e7751d;
  color: #fff; }

.fc-event-default, .fc-event-default .fc-event-skin, .fc-event-default .fc-event-time {
  border-color: #ffc72c;
  background-color: #ffc72c;
  color: #000; }

.fc-border-separate td {
  border: solid #dddddd;
  border-width: 1px 0 0 1px; }

.event-details {
  position: absolute;
  z-index: 10;
  top: 7%;
  left: 7%;
  width: 86%;
  height: 86%;
  overflow: auto;
  background: #fff;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0px 0px 8px 2px #dddddd;
  box-shadow: 0px 0px 8px 2px #dddddd; }
  .event-details .event-details-close {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0.5em;
    cursor: pointer; }
  .event-details .event-details-content {
    padding: 2em; }
    .event-details .event-details-content div {
      margin-bottom: 0.5em; }
  .event-details .event-title {
    color: #b31b1b;
    font-size: 2rem;
    padding-bottom: 1em; }
  .event-details .event-rsvp {
    margin-top: 1em; }
  .event-details .event-rsvp a {
    padding-right: 1em;
    font-size: 1.3rem; }
  .event-details .label-title {
    display: inline-block;
    vertical-align: top; }
    @media screen and (min-width: 768px) {
      .event-details .label-title {
        width: 25%; } }
  .event-details .label-info {
    background: none;
    display: inline-block;
    vertical-align: top; }
    @media screen and (min-width: 768px) {
      .event-details .label-info {
        width: 75%; } }

.view-practice-videos:before, .view-practice-videos:after {
  content: " ";
  display: table; }

.view-practice-videos:after {
  clear: both; }

.view-practice-videos h3 {
  clear: both;
  margin-top: 1em;
  float: left;
  width: 100%; }

.view-practice-videos .views-row {
  float: left;
  margin-bottom: 2em;
  /*&:nth-child(3) {
                margin-right: 0;
            }*/ }
  @media screen and (min-width: 768px) {
    .view-practice-videos .views-row {
      width: 31.33%;
      margin: 0 2% 0 0; } }
  .view-practice-videos .views-row a:after {
    content: "";
    display: none !important; }
  .view-practice-videos .views-row a > img {
    border-radius: 0; }

.all-events-month {
  margin: 1em 0; }
  .all-events-month .event-info {
    margin-bottom: 1em; }

.pane-events-calendar-panel-pane-2 {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2em;
  padding-top: 2em;
  border-top: 1px solid #dddddd; }
  .pane-events-calendar-panel-pane-2:before, .pane-events-calendar-panel-pane-2:after {
    content: " ";
    display: table; }
  .pane-events-calendar-panel-pane-2:after {
    clear: both; }
  .pane-events-calendar-panel-pane-2 .fullcalendar {
    float: none;
    /*.fc-content {
                tr {
                    border: 1px solid #ccc;
                    border-collapse: inherit;
                }
            }*/ }
    @media screen and (min-width: 768px) {
      .pane-events-calendar-panel-pane-2 .fullcalendar {
        margin-right: 0em;
        float: left; } }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-border-separate .ui-widget-content {
      width: 44px;
      vertical-align: middle; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header {
      background: #666666;
      color: #fff;
      margin-bottom: 0; }
      .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header h2 {
        color: #fff; }
      .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .fc-button {
        background: none; }
        .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .fc-button.ui-state-hover {
          border: none; }
        .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .fc-button .fc-button-content {
          line-height: 1; }
      .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .ui-icon-circle-triangle-w {
        background: none;
        position: relative;
        text-indent: inherit; }
        .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .ui-icon-circle-triangle-w:before {
          color: #fff;
          font-size: 1.3rem;
          content: ' \e810';
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          margin-right: .2em;
          text-align: center;
          /* opacity: .8; */
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          margin-left: .2em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .ui-icon-circle-triangle-e {
        background: none;
        position: relative;
        text-indent: inherit; }
        .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header .ui-icon-circle-triangle-e:before {
          color: #fff;
          font-size: 1.3rem;
          content: ' \e802';
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          margin-right: .2em;
          text-align: center;
          /* opacity: .8; */
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          margin-left: .2em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-grid .fc-day-number {
      float: none;
      text-align: center;
      line-height: 3;
      cursor: pointer; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-grid .fc-day-content {
      display: none; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .ui-widget-header {
      background: #fff;
      color: #000;
      border: 1px solid #ccc;
      border-right: none; }
      .pane-events-calendar-panel-pane-2 .fullcalendar .ui-widget-header:last-child {
        border-right: 1px solid #ccc; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header-title h2 {
      margin-bottom: 0;
      font-size: 1.5rem;
      padding-bottom: 0;
      padding-top: 0.5em; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header-right .fc-header-space, .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header-right .fc-button-month, .pane-events-calendar-panel-pane-2 .fullcalendar .fc-header-right .fc-button-agendaDay {
      display: none; }
    .pane-events-calendar-panel-pane-2 .fullcalendar .fc-border-separate .fc-widget-content {
      width: 44px;
      vertical-align: middle; }
  .pane-events-calendar-panel-pane-2 .mini-calendar {
    float: none; }
    @media screen and (min-width: 768px) {
      .pane-events-calendar-panel-pane-2 .mini-calendar {
        float: left; } }
    .pane-events-calendar-panel-pane-2 .mini-calendar h3 {
      font-size: 1.5rem; }
  .pane-events-calendar-panel-pane-2 .fc-event {
    display: none; }

.information-sidebar .pane-events-calendar-panel-pane-2 .fc-grid .fc-day-number {
  line-height: 2; }

.page-node-6 iframe {
  width: 100%; }

.no-js .page-node-6 iframe {
  display: none; }

.node-webform {
  margin-bottom: 1em; }
  .node-webform h2 {
    display: none; }

.information-column .icon-link {
  text-align: center; }
  .information-column .icon-link .cta-icon {
    margin-right: 5px;
    position: relative;
    top: 4px; }

.information-column .ss-icon {
  margin-right: 10px; }

.information-column .cta-icon {
  color: #cf4520;
  fill: #e7751d;
  font-size: 18px; }

div.caption-inner {
  border: none;
  padding: 0;
  background: none;
  text-align: left; }
  div.caption-inner p.caption-text {
    font-style: italic; }

.floor-plan-container {
  background: #efefef;
  padding: 20px 10px;
  margin-bottom: 2em; }
  .floor-plan-container .title {
    margin-bottom: 1em;
    font-size: 2rem;
    line-height: 1.3; }
    .floor-plan-container .title span {
      background: #b31b1b;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 50%;
      font-size: 2rem;
      color: #fff;
      width: 30px;
      height: 30px;
      border: 2px solid #fff;
      display: inline-block;
      text-align: center; }
  .floor-plan-container img {
    margin-bottom: 1em; }

.front footer {
  padding-top: 0; }

.front .site-main {
  max-width: none;
  padding: 0; }
  .front .site-main h2 {
    color: black; }
    .front .site-main h2:hover .arrow-icon-black {
      margin-left: 15px; }
  .front .site-main .home-block-half a:hover .arrow-icon {
    margin-left: 15px; }
  .front .site-main #sail-home-middle-full {
    background: #e7751d; }
    .front .site-main #sail-home-middle-full:before, .front .site-main #sail-home-middle-full:after {
      content: " ";
      display: table; }
    .front .site-main #sail-home-middle-full:after {
      clear: both; }
    .front .site-main #sail-home-middle-full .panel-pane {
      max-width: 960px;
      margin: 0 auto; }
  .front .site-main #middle-grid-right .fieldable-panels-pane, .front .site-main #middle-grid-right2 .fieldable-panels-pane {
    overflow: hidden; }
  .front .site-main #middle-grid-right img, .front .site-main #middle-grid-right2 img {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
    margin-bottom: 0; }
    .front .site-main #middle-grid-right img.scale, .front .site-main #middle-grid-right img:hover, .front .site-main #middle-grid-right2 img.scale, .front .site-main #middle-grid-right2 img:hover {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }
  .front .site-main .home-block-half {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #e7751d;
    color: #fff;
    padding: 2.6em 10px;
    font-size: 1.8rem;
    position: relative; }
    @media screen and (min-width: 768px) {
      .front .site-main .home-block-half {
        width: 50%;
        float: left; } }
    .front .site-main .home-block-half:first-child {
      background: url(/sites/default/files/styles/panopoly_image_full/public/general/img-horizontal2.png) no-repeat;
      background-size: cover;
      padding: 3.1em 30px; }
      .front .site-main .home-block-half:first-child .home-block-half-content {
        position: relative; }
      .front .site-main .home-block-half:first-child .home-block-half-bg {
        background: rgba(232, 119, 34, 0.7);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
    .front .site-main .home-block-half:last-child {
      font-size: 3rem;
      text-align: center; }
      .front .site-main .home-block-half:last-child a {
        color: #fff; }
    .front .site-main .home-block-half .arrow-icon {
      position: relative;
      height: 20px;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      background-color: white;
      display: inline-block;
      zoom: 1;
      margin-left: 10px;
      padding: 10px 5px 0px 0px;
      color: white; }
      .front .site-main .home-block-half .arrow-icon:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 5px solid white;
        border-bottom: 10px solid transparent;
        margin: -10px 0px 0 5px; }
      .front .site-main .home-block-half .arrow-icon:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        border-top: 10px solid transparent;
        border-left: 5px solid #e7751d;
        border-bottom: 10px solid transparent;
        margin: -10px 0px 0 0px; }
  .front .site-main .arrow-icon-black {
    position: relative;
    height: 20px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    background-color: #e7751d;
    display: inline-block;
    zoom: 1;
    margin-left: 10px;
    padding: 10px 5px 0px 0px;
    color: #e7751d; }
    .front .site-main .arrow-icon-black:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 5px solid #e7751d;
      border-bottom: 10px solid transparent;
      margin: -10px 0px 0 5px; }
    .front .site-main .arrow-icon-black:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 0;
      border-top: 10px solid transparent;
      border-left: 5px solid white;
      border-bottom: 10px solid transparent;
      margin: -10px 0px 0 0px; }
  .front .site-main .home-intro {
    position: relative;
    height: auto; }
    @media screen and (min-width: 992px) {
      .front .site-main .home-intro {
        height: 420px;
        overflow: hidden; } }
    .front .site-main .home-intro .home-intro__image {
      display: block; }
      @media screen and (min-width: 992px) {
        .front .site-main .home-intro .home-intro__image {
          display: none; } }
    .front .site-main .home-intro #bgvid {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      @media screen and (min-width: 992px) {
        .front .site-main .home-intro #bgvid {
          display: block; } }

/* =============================================================================
   ~Footer
   ========================================================================== */
@media screen and (min-width: 768px) {
  .footer-contact__contact-us a {
    padding: 20px 28px; } }

@media screen and (min-width: 992px) {
  .footer-contact__contact-us a {
    padding: 20px 48px; } }

/*
#footer-nav a{
  color: $base-color;
}

.disclaimer, .disclaimer a{
  color: $base-color;
}*/
.fc-content a:link, .fc-content a:visited {
  color: #cf4520; }

.emptyProfile {
  background: none !important; }

@media only screen and (max-width: 767px) {
  .emptyProfile {
    margin-bottom: -24px !important;
    height: 0 !important; } }
