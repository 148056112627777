$primary-nav-width: 33%;
$btn-color: #3787b0;

/* ========================= */

#primary-nav .level-1 {
  width: $primary-nav-width; //Customize me based on content!
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

.brand__lockup img {
    height: 84px;
}

/* =========================
   ~ Mixins
   ====================== */
@mixin arrow($bg-color: #000, $color: #fff) {
    position: relative;
    height: 20px;
    @include transition(all 0.25s);
    background-color: $color;
    display: inline-block;
    zoom: 1;
    margin-left: 10px;
    padding: 10px 5px 0px 0px;
    color: $color;
        
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 5px solid $color;
        border-bottom: 10px solid transparent;
        margin: -10px 0px 0 5px; 
    }
    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        border-top: 10px solid transparent;
        border-left: 5px solid $bg-color;
        border-bottom: 10px solid transparent;
        margin: -10px 0px 0 0px; 
    }
}

@mixin icon-after($character, $color: $wcm-dark-orange) {
  &:after {
    color: $color;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    //    width: 1em;
    margin-right: .2em;
    padding-left: 3px;
    text-align: center;

    font-variant: normal;
    text-transform: none;

    line-height: 1em;

    //    margin-left: .2em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: $character;
  }
  &:hover, &:active {
    &:after {
      text-decoration: none;
    }
  }
}

/* =============================================================================
   ~Components
   ========================================================================== */

    /*.hero-image {
//        margin: 0 -5px;
        @include breakpoint($sm) {
            margin: 0 auto 1em;
        }
    }*/

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 20px;
    }
}

.contact-info {
    padding-top: 10px;
}

.node-type-news-post {
    .post-date {
        color: $wcm-med-gray;
        font-weight: 600;
        text-transform: uppercase;
    }

    .pane-node-field-news-category {
        clear: both;
    }

    .hero-image {
        margin-bottom: 20px;
    }
    
    .field-name-field-news-category {
        h3 {
            color: #000;
            margin-top: 0;
            font-size: inherit;
            line-height: inherit;
        }
    }
}

.addthis_toolbox {
    margin-bottom: 10px;

    .at-icon-wrapper {
        display: none;
    }
    .btn-share {
//        border: 1px solid $wcm-bright-orange;
        border-radius: 50%;
        margin-right: 10px;
        width: 22px !important;
        height: 22px;
    }
        
    .icon-facebook {
        @include icon-after('\e804', $wcm-bright-orange);
        &:after {
//            padding-left: 8px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
    .icon-twitter {
        @include icon-after('\e807', $wcm-bright-orange);
        &:after {
            padding-left: 0px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
    .icon-linkedin {
        @include icon-after('\e806', $wcm-bright-orange);
        &:after {
//            padding-left: 7px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
    .icon-email {
        @include icon-after('\e80c', $wcm-bright-orange);
        &:after {
//            padding-left: 5px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
}


.select2-container {
    width: 280px !important;
}

.pager--infinite-scroll {
    li a {
        border-radius: 20px;
        border: 4px solid $wcm-bright-orange;
        padding: 6px 20px;
        font-size: 13px;
        width: 220px;
        
        &:hover, &:focus {
            border-color: $wcm-dark-orange;
            background-color: $wcm-bright-orange;
            color: #fff;
        }
    }
}

.view-news {
    .view-teaser {
        border-bottom: 1px solid $wcm-border-gray;
        padding-top: 20px;
    }
}

.view-teaser {
    .teaser-title {
        a {
            color: $wcm-dark-gray;
        }
    }
    .post-date {
        color: $wcm-med-gray;
        font-weight: 600;
        text-transform: uppercase;
    }
    img.panopoly-image-quarter {
        float: none;
        margin: 0 0 0.25em 0;
        width: auto;
        max-width: none;
        @include breakpoint($sm) {
            float: left;
            margin: 0.25em 1.5em 1.3em 0;
            width: 35%;
            max-width: 35%;
        }
    }
}

    .view-profiles {
        @include clearfix();
        .views-view-grid {
            margin: 0;
            
            .visible-sm {
                @include breakpoint($sm) {
                    display: none !important;
                }
            }
        }
        .grid-title {
            display: none;
        }
        .gridcol {
            &:after {
                content: '';
                position: absolute;
                height: 100%;
                background: #efefef;
            }
//        .views-row {
//            @include box-sizing(border-box);
            // @include transition(all 0.25s);
            position: relative;
            background: #efefef;
            text-align: center;
            @include breakpoint($sm) {
//                width: 31%;
//                margin-right: 22px;
                width: 28%;
                margin-right: 8%;
                // margin-right: 67px;
                text-align: left;
            }
            float: left;
            padding: 12px;
            margin-bottom: 2em;            
            
            // &:nth-child(7), 
            // &:nth-child(7n+9) {
            &:nth-child(3n) {
                margin-right: 0;
            }
            
            .profile-list-image {
                text-align: center;
                img {
                    width: 144px;
                    /*@include breakpoint($sm) {
                        width: 100%;
                    }*/
                }
            }
            
            h2 {
                color: #000;
                font-size: 1.5rem;
            }
//            h3 {
            .professional-title {
//                font-family: $cornell-sans-regular;
                font-weight: 400;
                color: #000;
                line-height: 1.4;
                font-size: 1.4rem;
                text-transform: none;
                margin-bottom: 1em;
            }
            
            .profile_link {
                @include breakpoint($sm) {
                    display: none;
                    padding: 0;
                    /*position: absolute;
                    bottom: 5px;*/
                }
                p {
                    margin: 0;
                }
                a {
                    display: block;
                    color: #000;
                    @include breakpoint($sm) {
                        color: $wcm-yellow;
                    }
//                    font-weight: 700;
                    font-size: 1.4rem;
                }
            }
            @include breakpoint($sm) {
                &:not(.no-hover):hover {
                    background: #b61911;
                    color: #fff;

                    .profile_link {
                        display: block;
                    }

                    h2 {
                        color: #fff;
                    }

//                    h3 {
                        .professional-title {
                        display: none;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .view-services-programs {
        clear: both;
        
        .views-row {
            @include clearfix();
            border-bottom: 1px solid #efefef;
            padding-bottom: 2em;
            margin-bottom: 2em;
            
            .views-field-title {
//                font-size: 2.5rem;
                h3 {
                    margin-top: 0;
                }
                a {
//                    color: #000;
//                    &:after {
//                        content: "<span>A</span>";
//                    }
//                    @include arrow(#000);
                }
            }
            
            
            img {
                border-radius: 0;
                margin-bottom: 1em;
                @include breakpoint($sm) {
                    float: left;
                    margin-right: 25px;
                    margin-bottom: 0em;
                }
            }
        }
    }

    .field-collection-container {
        border-bottom: none;
    }

    .services-accordion {
        position: relative;
        h3 {
            border-top: 1px solid $wcm-border-gray;
            background: none;
            padding-left: 0;
            padding-top: 15px;
            margin-top: 15px;
            color: #000;
//            font-size: 2.5rem;
//            border: none;
            cursor: pointer;
            .ui-icon {
                display: none;
            }
            &:first-child {
                border-top: none;
            }
            /*&:after {
                content: "+";
            }*/
            &.state-active {
                /*.arrow-right {
                    -webkit-transform: rotate(90deg);
                    -moz-transform:    rotate(90deg);
                    -ms-transform:     rotate(90deg);
                    -o-transform:      rotate(90deg);
                    transform:         rotate(90deg);
                }*/
                
                
                .arrow-right {
                    position: relative;
                    width: 16px;
                    height: auto;
                    background-color: #000;
                    display: inline-block;
                    zoom: 1;
                    margin-left: 15px;
                    margin-bottom: 8px;
                    padding: 5px 10px 0px 0px;
                    color: white;

                    &:after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        left: 0;
                        border-top: 5px solid #000;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: none;
                        margin: 0px 0px 0 0px; 
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        left: 0;
                        border-top: 5px solid white;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        margin: -5px 0px 0 0px; 
                    }
                }
                
            }
            
            .arrow-right {
                position: relative;
                height: 16px;
//                @include transition(all 0.25s);
                background-color: #000;
                display: inline-block;
                zoom: 1;
                margin-left: 15px;
                padding: 10px 5px 0px 0px;
                color: white;
                
                &:after {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 8px solid transparent;
                    border-left: 5px solid #000;
                    border-bottom: 8px solid transparent;
                    margin: -10px 0px 0 5px; 
                }
                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: 0;
                    border-top: 8px solid transparent;
                    border-left: 5px solid white;
                    border-bottom: 8px solid transparent;
                    margin: -10px 0px 0 0px; 
                }
            }
        }
        .accordion-panel {
            display: none;
            background: $wcm-bg-gray; // #efefef;
            padding: 20px;
            margin-bottom: 2em;
        }
    }

.pane-events-calendar-panel-pane-3 {
    @include clearfix();
    margin-bottom: 3em;
    h3 {
        margin-bottom: 1em;
    }
    
    .views-row {
        @include box-sizing(border-box);
        width: 30%;
        float: left;
        margin-right: 5%;
        border-right: 1px solid $wcm-border-gray;
        &:last-child {
            margin-right: 0;
            border-right: none;
        }
        
        .views-field-title {
            float: left;
            width: 68%;
            @include box-sizing(border-box);
            font-size: 1.6rem;
        }
    }
}
.pane-events-calendar-panel-pane-1 {
    margin-top: 30px;
    .fc-header-title h2 {
        font-size: 20px;
    }

    .fc-button {
        &.ui-state-hover {
            border: none;
            background: #cccccc;
        }
        .fc-button-content {
            line-height: 1;
        }
        // background: none;
    }
}
.pane-events-calendar-panel-pane-3 .event-date {
    float: left;
    line-height: .9;
//    text-align: right;
    padding: 3px 8px 0 0;
    width: 32%;
//    margin-right: 13px;
//    border-right: 1px solid $cornell-light-gray;
    font-weight: bold;
    color: #2f3237;
    min-width: 55px;
    @include box-sizing(border-box);
    font-size: 1.7rem;
    
    p.days {
        margin: 0;
        margin-top: 5px;
        font-size: 2.8rem;
    }
}
    
    .fc-event-rsvp-link,
    .fc-event-location,
    .fc-event-info, 
    .fc-event-date {
        display: none;
    }

    .ui-state-highlight {
        background: $wcm-bright-orange;
        color: $wcm-white;
    }

    .fc-event-default, .fc-event-default .fc-event-skin, .fc-event-default .fc-event-time {
        border-color: $wcm-yellow;
        background-color: $wcm-yellow;
        color: #000;
    }

    .fc-border-separate td {
        border: solid #dddddd;
        border-width: 1px 0 0 1px;
    }

    .event-details {
        position:absolute; 
        z-index:10; 
        top:7%; 
        left:7%; 
        width: 86%; 
        height:86%;
        overflow: auto;
        background: #fff;
        border: 1px solid #dddddd;// $light-gray;
        @include box-shadow(0px 0px 8px 2px #dddddd);
        
        .event-details-close {
            float:right;
            margin-right: 10px;
            margin-top: 5px;
            padding: 0.5em;
            cursor: pointer;
        }
        
        .event-details-content {
            padding: 2em;
            
            div {
                margin-bottom: 0.5em;
            }
        }
        
        .event-title {
            color: $wcm-red;// $btn-color;
            font-size: 2rem;
            padding-bottom: 1em;
        }
        
        .event-rsvp {
            margin-top: 1em;
        }
        .event-rsvp a {
            padding-right: 1em;
            font-size: 1.3rem;
        }
        
        
        .label-title {
            @include breakpoint($sm) {
                width: 25%;
            }
            display: inline-block;
            vertical-align: top;
        }
        .label-info {
            @include breakpoint($sm) {
                width: 75%;
            }
            background: none;
            display: inline-block;
            vertical-align: top;
        }
    }

    .view-practice-videos {
        @include clearfix();
        h3 {
            clear: both;
            margin-top: 1em;
            float: left;
            width: 100%;
        }
        .views-row {
//            float: left;
//            width: 33%;
            
            @include breakpoint($sm) {
                width: 31.33%;
                margin: 0 2% 0 0;
            }
            float: left;
//            padding: 12px;
            margin-bottom: 2em;
            
            /*&:nth-child(3) {
                margin-right: 0;
            }*/
            
            a {
                &:after {
                    content: "";
                    display: none !important;
                }
                > img {
                    border-radius: 0;
                }
            }
        }
    }

    .all-events-month {
        margin: 1em 0;
        
        .event-info {
            margin-bottom: 1em;
        }
    }

    .pane-events-calendar-panel-pane-2 {
        @include clearfix();
//        float: left;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 2em;
        padding-top: 2em;
        border-top: 1px solid $wcm-border-gray;
                
        .fullcalendar {
//            width: 50%;
            float: none;
            @include breakpoint($sm) {
                margin-right: 0em;
                float: left;
            }
            
            /*.fc-content {
                tr {
                    border: 1px solid #ccc;
                    border-collapse: inherit;
                }
            }*/
            


            .fc-border-separate  .ui-widget-content {
                width: 44px;
                vertical-align: middle;
            }
            
            .fc-header {
                background: $wcm-med-gray;
                color: #fff;
                margin-bottom: 0;
                
                h2 {
                    color: #fff;
                }
                
                .fc-button {
                    &.ui-state-hover {
                        border: none;
                    }
                    .fc-button-content {
                        line-height: 1;
                    }
                    background: none;
                }
                
                .ui-icon-circle-triangle-w {
//                    background-position: -97px 0px;
                    background: none;
                    position: relative;
                    text-indent: inherit;
                    &:before {
                        color: #fff;
                        font-size: 1.3rem;
                        content: ' \e810';
                        font-family: "fontello";
                        
                        font-style: normal;
                        font-weight: normal;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        width: 1em;
                        margin-right: .2em;
                        text-align: center;
                        /* opacity: .8; */
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1em;
                        margin-left: .2em;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                    }
                }
                .ui-icon-circle-triangle-e {
//                    background-position: -33px 0px;
                    background: none;
                    position: relative;
                    text-indent: inherit;
                    &:before {
                        color: #fff;
                        font-size: 1.3rem;
                        content: ' \e802';
                        font-family: "fontello";
                        font-style: normal;
                        font-weight: normal;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        width: 1em;
                        margin-right: .2em;
                        text-align: center;
                        /* opacity: .8; */
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1em;
                        margin-left: .2em;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                    }
                }
            }
                        
            .fc-grid .fc-day-number {
                float: none;
                text-align: center;
                line-height: 3;
                cursor: pointer;
            }
            .fc-grid .fc-day-content {
                display: none;
            }
            
            .ui-widget-header {
                background: #fff;
                color: #000;
                border: 1px solid #ccc;
                border-right: none;
                &:last-child {
                    border-right: 1px solid #ccc;
                }
            }
            
            .fc-header-title h2 {
                margin-bottom: 0;
                font-size: 1.5rem;
                padding-bottom: 0;
                padding-top: 0.5em;
            }
            .fc-header-right {
                .fc-header-space, .fc-button-month, .fc-button-agendaDay {
                    display: none;
                }
            }
            
            .fc-border-separate .fc-widget-content {
                width: 44px;
                vertical-align: middle;
            }
        }
        .mini-calendar {
            float: none;
            @include breakpoint($sm) {
                float: left;
            }
            
            h3 {
                font-size: 1.5rem;
            }
        }
        .fc-event {
            display: none;
        }
    }

    .information-sidebar .pane-events-calendar-panel-pane-2 .fc-grid .fc-day-number {
        line-height: 2;
    }

    .page-node-6 {
        iframe {
//            max-width: 600px;
            width: 100%;
        }

        .no-js & {
            iframe {
                display: none;
            }
        }
    }

    .node-webform {
        margin-bottom: 1em;
        h2 {
            display: none;
        }
    }
    
    .information-column {
        
        .icon-link {
            text-align: center;
            .cta-icon {
                margin-right: 5px;
                position: relative;
                top: 4px;
            }
        }
        
        .ss-icon {
            margin-right: 10px;
        }
        
        .cta-icon {
            color: $wcm-dark-orange;
            fill: $wcm-bright-orange;
//            margin-right: 5px;
            font-size: 18px;
        }
    }

    div.caption-inner {
        border: none;
        padding: 0;
        background: none;
        text-align: left;

        p.caption-text {
            font-style: italic;
        }
    }

    .floor-plan-container {
        background: #efefef;
        padding: 20px 10px;
        margin-bottom: 2em;
        
        .title {
            margin-bottom: 1em;
            font-size: 2rem;
            line-height: 1.3;
            
            span {
                background: $wcm-red;
                @include box-sizing(border-box);
                border-radius: 50%;
                font-size: 2rem;
                color: #fff;
                width: 30px;
                height: 30px;
                border: 2px solid #fff;
                display: inline-block;
                text-align: center;
            }
        }
        
        img {
            margin-bottom: 1em;
        }
    }

.front footer {
    padding-top: 0;
}
.front .site-main {
    max-width: none;
    padding: 0;
    
    h2 {
        color: black;
        &:hover {
            .arrow-icon-black {
                margin-left: 15px;
            }
        }
    }
    
    .home-block-half a {
        &:hover {
            .arrow-icon {
                margin-left: 15px;
            }
        }
    }
    
    #sail-home-middle-full {
        background: $wcm-bright-orange; // $wcm-red;
        @include clearfix();
//        padding: 2em 0;
        
        .panel-pane {
            max-width: 960px;
            margin: 0 auto;
//            padding: 0 10px;
        }
    }
    
    #middle-grid-right, #middle-grid-right2 {
        .fieldable-panels-pane {
            overflow: hidden;
//            margin-bottom: .5em;
        }
        img {
            @include transition(0.25s all ease);
            margin-bottom: 0;
            
            &.scale, &:hover {
                @include scale(1.2);
            }
        }
    }
        
    
    .home-block-half {
        @include box-sizing(border-box);
        @include breakpoint($sm) {
            width: 50%;
            float: left;
        }
        background: $wcm-bright-orange;
        color: #fff;
        padding: 2.6em 10px;
        font-size: 1.8rem;
        position: relative;
        
        &:first-child {
            background: url(/sites/default/files/styles/panopoly_image_full/public/general/img-horizontal2.png) no-repeat;
            background-size: cover;
            padding: 3.1em 30px;
            
            .home-block-half-content {
                position: relative;
            }
            
            .home-block-half-bg {
                background: rgba(232, 119, 34, 0.7);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        
        &:last-child {
            font-size: 3rem;
            text-align: center;
//            font-family: $wcm-bold;
            
            a {
                color: #fff;
            }
        }
        
        .arrow-icon {
            @include arrow($wcm-bright-orange, white);
        }
    }
    
    .arrow-icon-black {
        @include arrow(white, $wcm-bright-orange);
    }
    
    .home-intro {
        position: relative;
        height: auto;
        @include breakpoint($md) {
            height: 420px;
            overflow: hidden;
        }
        
        .home-intro__image {
            display: block;
            @include breakpoint($md) {
                display: none;
            }
        }
        
        #bgvid {
            display: none;
            @include breakpoint($md) {
                display: block;
            }
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }
    }
}
/* =============================================================================
   ~Footer
   ========================================================================== */

.footer-contact__contact-us a {
    @include breakpoint($sm) {
        padding: 20px 28px;
    }
    @include breakpoint($md) {
        padding: 20px 48px;
    }
}
/*
#footer-nav a{
  color: $base-color;
}

.disclaimer, .disclaimer a{
  color: $base-color;
}*/

.fc-content a:link, .fc-content a:visited {color:#cf4520}

.emptyProfile {
    background:none !important;
        
}

@media only screen and (max-width: 767px) {
    .emptyProfile {
        margin-bottom: -24px !important;
            height: 0 !important;
    }
}
